import Vue from 'vue'
import App from './App.vue'
import router from './router'
import api from './services/Webservice'
import shareService from './services/ShareService'
import 'vuejs-noty/dist/vuejs-noty.css'
import VueNoty from 'vuejs-noty'
import VeeValidate from 'vee-validate'
import { LoaderPlugin } from 'vue-google-login';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import 'firebase/messaging'


Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')


Object.defineProperty(Vue.prototype, '$api', {
    get() {
        return api
    }
})

Object.defineProperty(Vue.prototype, '$shareService', {
    get() {
        return shareService
    }
})

Vue.use(VeeValidate, {
    classes: true
})

Vue.use(require('vue-moment'))

Vue.$api = api
Vue.$shareService = shareService

Vue.use(LoaderPlugin, {
    client_id: "486167013515-3o9qlqffbdhei5ic16i66ai9qb2sq06t.apps.googleusercontent.com",
    plugin_name:'App Name that you used in google developer console API'
});

Vue.use(VueNoty, {
    timeout: 1500
})

Vue.filter('titleCase', function(value) {
    if (!value) {
        return ''
    } else {
        var str = ''
        var arr = value.split('_')
        for (var i = 0; i < arr.length; i++) {
            if (i == 0) {
                str = arr[i].charAt(0).toUpperCase() + arr[i].substring(1).toLowerCase();
            } else {
                str = str + ' ' + arr[i].charAt(0).toUpperCase() + arr[i].substring(1).toLowerCase();
            }
        }
        return str
    }
})

Vue.filter('hairkutValues', function(value) {
    if (!value) {
        return ''
    } else {
        var str = ''
        var arr = value.split('|')
        for (var i = 0; i < arr.length; i++) {
            if (i == 0) {
                str = arr[i];
            } else {
                str = str + ', ' + arr[i];
            }
        }
    }
    return str
})

// // Initialize Firebase

if (navigator.serviceWorker) {
    setInterval(() => {
            navigator.serviceWorker.register('/firebase-messaging-sw.js')
                .then((registration) => {
                    const firebaseApp = initializeApp({
                        apiKey: "AIzaSyCcOxVeDSIcZljKkUyDPQm_1Gf52CovRX4",
                        authDomain: "haircut-customer-app.firebaseapp.com",
                        projectId: "haircut-customer-app",
                        storageBucket: "haircut-customer-app.appspot.com",
                        messagingSenderId: "423891850043",
                        appId: "1:423891850043:web:9239f8c194d0ce968f9d1e",
                        measurementId: "G-BCGXXNYR9T"
                    });




                    const messaging = getMessaging(firebaseApp);

                    if (!localStorage.getItem("push_token")) {
                        getToken(messaging, {
                                serviceWorkerRegistration: registration,
                                vapidKey: "BPRKM30XPlGOPvYpzpZT5QKyxtc38NM8iBNV1VCLy35YKmC5Vg72PEac7foX9FbDo5-dSqDrn8sVRYPJcVZfpP8",
                            })
                            .then((currentToken) => {
                                if (currentToken) {
                                    console.log("token " + currentToken);
                                    localStorage.setItem("push_token", currentToken)
                                } else {
                                    console.log(
                                        "No registration token available. Request permission to generate one."
                                    );
                                }
                            })
                            .catch((err) => {
                                console.log("An error occurred while retrieving token. ", err);
                            });
                    }


                    onMessage(messaging, (payload) => {
                        console.log("Payload", payload)
                        const notificationOptions = {
                            body: payload.notification.body + payload.data ? moment.utc(payload.data.date).local().format("DD MMM YYYY hh:mm A") : '',
                            icon: payload.notification.image,
                        };
                        registration.showNotification(
                            payload.notification.title,
                            notificationOptions
                        );
                        console.log("notifiva", registration.showNotification(
                            payload.notification.title,
                            notificationOptions
                        ))
                    });
                    console.log('Registration successful, scope is:', registration.scope);

                }).catch(err => {
                    console.log(err)
                })
        }, 5000) // every 5 seconds
}