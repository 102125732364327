import axios from 'axios'
import Noty from 'noty';
import moment from 'moment'
import 'moment-timezone';
import router from './../router'

var http = null
// var baseURL = "https://haircut.winayak.com/api/"
var baseURL = "https://saloon.cybertrons.io/api/"

// var baseURL = "http://127.0.0.1:8000/api/"

class WebService {
    constructor({ }) {
        http = axios.create({
            baseURL: baseURL
        })
        // this.getConfig();
        // this.setSessionTime();
    }

    getAPI({ _action, _body, _buttonId, _hide_loader }) {
        let params = new URLSearchParams()
        for (let key in _body) {
            params.set(key, _body[key])
        }

        if (this.addLoader(_buttonId, _hide_loader)) {
            return
        }

        return http
            .get(_action, {
                params: params,
                headers: this.getHeader()
            })
            .then(response => {
                this.removeLoader(_buttonId, _hide_loader)
                return response.data
            })
            .catch(e => {
                this.removeLoader(_buttonId, _hide_loader)
                return this._errorHandler(e)
            })
    }

    postAPI({ _action, _body, _buttonId, _hide_loader, _hide_error }) {
        if (this.addLoader(_buttonId, _hide_loader)) {
            return
        }

        return http
            .post(_action, JSON.stringify(_body), { headers: this.getHeader() })
            .then(response => {
                this.removeLoader(_buttonId, _hide_loader)
                return response.data
            })
            .catch(e => {
                this.removeLoader(_buttonId, _hide_loader)
                return this._errorHandler(e, _hide_error)
            })
    }

    putAPI({ _action, _body, _buttonId, _hide_loader }) {
        if (this.addLoader(_buttonId, _hide_loader)) {
            return
        }
        return http
            .put(_action, JSON.stringify(_body), { headers: this.getHeader() })
            .then(response => {
                this.removeLoader(_buttonId, _hide_loader)
                return response.data
            })
            .catch(e => {
                this.removeLoader(_buttonId, _hide_loader)
                return this._errorHandler(e)
            })
    }

    deleteAPI({ _action, _buttonId, _hide_loader }) {
        if (this.addLoader(_buttonId, _hide_loader)) {
            return
        }
        return http
            .delete(_action, { headers: this.getHeader() })
            .then(response => {
                this.removeLoader(_buttonId, _hide_loader)
                return response.data
            })
            .catch(e => {
                this.removeLoader(_buttonId, _hide_loader)
                return this._errorHandler(e)
            })
    }


    uploadImageAPI({ _action, _file, _key, _body, _buttonId, _hide_loader }) {
        if (!_key) {
            _key = 'file'
        }
        if (this.addLoader(_buttonId, _hide_loader)) {
            return
        }
        let formData = new FormData()
        formData.append(_key, _file)
        for (let key in _body) {
            formData.append(key, _body[key])
        }

        return http
            .post(_action, formData, { headers: this.addHeaderForMultipart() })
            .then(response => {
                this.removeLoader(_buttonId, _hide_loader)
                return response.data
            })
            .catch(e => {
                this.removeLoader(_buttonId, _hide_loader)
                return this._errorHandler(e)
            })
    }

    getAccesstoken({ _action, _body, _buttonId, _hide_loader }) {
        if (this.addLoader(_buttonId, _hide_loader)) {
            return
        }
        var headers = {}

        headers['Content-Type'] = 'application/json'

        return http
            .post(_action, JSON.stringify(_body), null, { headers: headers })
            .then(response => {
                localStorage.setItem('access_token', response.data.access_token)
                this.removeLoader(_buttonId, _hide_loader)
                return response.data
            })
            .catch(e => {
                this.removeLoader(_buttonId, _hide_loader)
                return this._loginErrorHandler(e)
            })

    }

    _loginErrorHandler(error) {
        if (error.response != null) {
            error = error.response
        }
        var errorMessage
        if (!error || !error.status) {
            errorMessage = 'Server Not Responding'
        } else if (error.status === 400 || error.status === 404) {
            errorMessage =
                (error && error.data && error.data.message) ||
                'Invalid Username or Password'
        } else if (error.status === 500) {
            errorMessage = 'Server Error'
        } else {
            errorMessage =
                (error & error.data && error.data.message) || 'Bad Response'
        }
        new Noty({
            type: 'error',
            text: errorMessage,
            timeout: 2000
        }).show()
        throw new Error(errorMessage)
    }
    _errorHandler(error) {

        if (error.response != null) {
            error = error.response
        }
        var errorMessage
        if (!error || !error.status) {
            errorMessage = 'Server Not Responding'
        } else if (error.status === 401) {
            this.logout()
            errorMessage = (error && error.data && error.data.message) || 'Bad Response'
        } else if (error.status === 500) {
            errorMessage = 'Server Error'
        } else {
            errorMessage =
                (error && error.data && error.data.message) || 'Bad Response'
        }

        new Noty({
            type: 'error',
            text: errorMessage,
            timeout: 2000
        }).show()
        throw new Error(errorMessage)
    }

    logout() {
        localStorage.clear()
        router.push("/login")
    }

    getHeader() {
        var headers = {}
        headers['Content-Type'] = 'application/json'
        if (localStorage.getItem('access_token')) {
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('access_token')
        }
        return headers
    }

    addHeaderForMultipart() {
        if (localStorage.getItem('access_token')) {
            var headers = {}
            if (localStorage.getItem('access_token')) {
                headers['Authorization'] =
                    'Bearer ' + localStorage.getItem('access_token')
            }
            return headers
        } else {
            // logout app
        }
    }

    addLoader(id, _hide_loader) {
        if (id) {
            var button = document.getElementById(id)
            if (button.disabled) {
                return true
            }
            button.disabled = true
            var loader = document.createElement('i')
            loader.className = 'fa fa-spinner fa-spin mr-2'
            button.prepend(loader)
            return false
        }
    }

    getBaseUrl() {
        return baseURL
    }

    getServerUrl() {
        return process.env.APP_URL;
    }

    removeLoader(id, _hide_loader) {
        if (id && !_hide_loader) {
            var button = document.getElementById(id)
            button.disabled = false
            button.removeChild(button.childNodes[0])
        }
    }

    converTime(value) {
        if (value) {
            return moment.utc(value, 'HH:mm:ss').local().format("hh:mm A");
        }

        return value;
    }
    converTimes(value) {
        if (value) {
            return moment.utc(value, 'HH:mm A').local().format("hh:mm A");
        }

        return value;
    }
    converDateTime(value) {
        if (value) {
            return moment.utc(value).local().format("DD/MM/YYYY HH:mm");
        }

        return value;
    }
    converBookingTime(value) {
        if (value) {
            return moment.utc(value).local().format("DD MMM YYYY hh:mm A");
        }

        return value;
    }
    converServerTime(value) {
        if (value) {
            return moment(value, 'hh:mm A').utc().format("HH:mm");
        }
        return "";
    }
    allowCharater(e) {
        if (
            (e.charCode > 64 && e.charCode < 91) ||
            (e.charCode > 96 && e.charCode < 123) ||
            e.charCode == 32
        ) {
            return true;
        } else {
            e.preventDefault();
            return false;
        }
    }
    allowNumber(e) {
        if (!/\d/.test(e.key) && e.key !== '.') {
            return e.preventDefault();
        }
    }


    convertTimeToUTC(e) {
        return moment(e, "YYYY-MM-DD HH:mm:ss").utc().format("YYYY-MM-DD HH:mm:ss");
    }

    convertTimeToLocal(e) {
        return moment.utc(e).local().format("YYYY-MM-DD HH:mm:ss");
    }
    convertToLocals(e) {
        return moment.utc(e).local().format("HH:mm:A, DD MMM YYYY ");
    }
    convertTimeToLocals(e) {
        return moment.utc(e, "HH:mm").local().format("HH:mm");
    }
    formatPrice(value) {
        if (!value) {
            return 0.00
        }
        return value.toFixed(2)
    }

    getText(text, length) {
        if (text && length && text.length > length) {
            return text.substring(0, length) + "...";
        }

        return text;
    }

}
export default new WebService({})