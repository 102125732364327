import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

let router = new Router({
    mode: 'history',
    routes: [{
        path: '/',
        name: 'Index',
        component: () =>
            import('@/views/OnBoarding/Index')
    }, {
        path: '/signup',
        name: 'SignUp',
        component: () =>
            import('@/views/OnBoarding/SignUp')
    },
    {
        path: '/custumer/signup',
        name: 'SignUp',
        component: () =>
            import('@/views/OnBoarding/CustumerSignUp')
    },
    {
        path: '/signup/:type',
        name: 'SignUp',
        component: () =>
            import('@/views/OnBoarding/SignUp')
    },
    {
        path: '/custumer/signup/:type',
        name: 'SignUp',
        component: () =>
            import('@/views/OnBoarding/CustumerSignUp')
    },
    {
        path: '/login',
        name: 'Login',
        component: () =>
            import('@/views/OnBoarding/login')
    },
    {
        path: '/forgot-password',
        name: 'Forgot-Password',
        component: () =>
            import('@/views/OnBoarding/ForgotPassword')
    }, {
        path: '/set-password',
        name: 'Set-Password',
        component: () =>
            import('@/views/OnBoarding/SetPassword')
    },
    {
        path: '/otp',
        name: 'OTP',
        component: () =>
            import('@/views/OnBoarding/OTP')
    },
    {
        path: '/terms',
        name: 'terms',
        component: () =>
            import('@/views/Terms-Privacy/termsAndConditions')
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: () =>
            import('@/views/Terms-Privacy/privacyPolicy')
    },

    {
        path: '/',
        component: () =>
            import('@/views/main'),
        meta: {
            requiresAuth: true
        },
        children: [{
            path: '/calendar',
            name: 'Calendar',
            component: () =>
                import('@/views/Calendar/calendar')
        },
        {
            path: '/calendar/:id',
            name: 'Calendar',
            component: () =>
                import('@/views/Calendar/calendar')
        },
        // {
        //     path: '/weekly-calendar',
        //     name: 'Weekly Calendar',
        //     component: () =>
        //         import ('@/views/Calendar/weekly-calendar')
        // },
        // {
        //     path: '/weekly-calendar/:id',
        //     name: 'Weekly Calendar',
        //     component: () =>
        //         import ('@/views/Calendar/weekly-calendar')
        // },
        // {
        //     path: '/yearly-calendar',
        //     name: 'Yearly Calendar',
        //     component: () =>
        //         import ('@/views/Calendar/yearly-calendar')
        // },
        // {
        //     path: '/yearly-calendar/:id',
        //     name: 'Yearly Calendar',
        //     component: () =>
        //         import ('@/views/Calendar/yearly-calendar')
        // },
        // {
        //     path: '/monthly-calendar/:month',
        //     name: 'Monthlt Calendar',
        //     component: () =>
        //         import ('@/views/Calendar/monthly-calendar')
        // },
        // {
        //     path: '/monthly-calendar/:month/:id',
        //     name: 'Monthlt Calendar',
        //     component: () =>
        //         import ('@/views/Calendar/monthly-calendar')
        // },

        {
            path: '/styles',
            name: 'Styles',
            component: () =>
                import('@/views/Home/style')
        },
        {
            path: '/style-detail/:id',
            name: 'Style',
            component: () =>
                import('@/views/Home/style-detail')
        },
        {
            path: '/stylists',
            name: 'Stylists',
            component: () =>
                import('@/views/Home/style-lists')
        },
        {
            path: '/stylist-detail/:type/:id',
            name: ' StylistDetail',
            component: () =>
                import('@/views/Home/stylist-detail')
        },
        {
            path: '/stylist-detail/:type/:id/:tab',
            name: ' StylistDetail',
            component: () =>
                import('@/views/Home/stylist-detail')
        },
        {
            path: '/manage-employees',
            name: 'ManageEmployee',
            component: () =>
                import('@/views/Employee/manage-employees')
        },
        {
            path: '/add-new-employee/:type',
            name: 'Add-New-Employee',
            component: () =>
                import('@/views/Home/stylist-detail')
        },
        {
            path: '/statistics',
            name: 'Statistics',
            component: () =>
                import('@/views/Home/statistics')
        },
        {
            path: '/settings',
            name: 'Settings',
            component: () =>
                import('@/views/Home/settings')
        },
        {
            path: '/update-kyc',
            name: 'UpdateKyc',
            component: () =>
                import('@/views/Home/kyc')
        },
        {
            path: '/style',
            name: 'Style',
            component: () =>
                import('@/views/Home/style')
        },
        {
            path: '/stylists',
            name: 'Stylists',
            component: () =>
                import('@/views/Home/style-lists')
        },
        {
            path: '/manage-employees',
            name: 'ManageEmployee',
            component: () =>
                import('@/views/Employee/manage-employees')
        },
        {
            path: '/statistics',
            name: 'Statistics',
            component: () =>
                import('@/views/Home/statistics')
        },

        {
            path: '/revenue-this-year',
            name: 'Revenue This Year',
            component: () =>
                import('@/views/Revenue/revenue-this-year')
        },
        {
            path: '/revenue-this-quater',
            name: 'Revenue This Quater',
            component: () =>
                import('@/views/Revenue/revenue-this-quater')
        },
        {
            path: '/revenue-this-year/:id',
            name: 'Revenue This Year',
            component: () =>
                import('@/views/Revenue/revenue-this-year')
        },
        {
            path: '/revenue-this-week',
            name: 'Revenue This Week',
            component: () =>
                import('@/views/Revenue/revenue-this-week')
        },
        {
            path: '/revenue-this-week/:id',
            name: 'Revenue This Week',
            component: () =>
                import('@/views/Revenue/revenue-this-week')
        },
        {
            path: '/revenue-this-month',
            name: 'Revenue This Month',
            component: () =>
                import('@/views/Revenue/revenue-this-month')
        },
        {
            path: '/revenue-this-month/:id',
            name: 'Revenue This Month',
            component: () =>
                import('@/views/Revenue/revenue-this-month')
        }



        ]
    }
    ]
})


router.beforeEach((to, from, next) => {
    document.title = `Hairkut :: ${to.name}`;
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem('access_token') == null) {
            next('/login')
        } else {
            next()
        }
    } else {
        if ((to.fullPath == '/signup' || to.fullPath == '/login' || to.fullPath == '/') && localStorage.getItem('access_token') != null) {
            next('/style')
        } else {
            next()
        }
    }
}


)

export default router
